import React from 'react';
import './App.css';


import Lids from './pages/Lids';
import Business from './pages/Business';
import Category from './pages/Category';
import Test from './pages/test';
import AddMeeting from './pages/addMeeting';
import AddMeetingCustomer from './pages/AddMeetingCustomer';
import Completeb  from './pages/complete_b';
import Completec  from './pages/complete_c';
import CancelBiz  from './pages/cancel_biz';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";

function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
      <Route path="/completec/:lid">
            <Completec />
          </Route>
          <Route path="/cancel-biz/:lid">
            <CancelBiz />
          </Route>
      <Route path="/completeb/:lid">
            <Completeb />
          </Route>
          <Route path="/category">
            <Category />
          </Route>
          <Route path="/test">
            <Test />
          </Route>
          <Route path="/business">
            <Business />
          </Route>
          <Route path="/addmeeting/:lid">
            <AddMeeting />
          </Route>
          <Route path="/addmeeting-customer/:lid">
            <AddMeetingCustomer />
          </Route>
          <Route path="/">
            <Lids />
          </Route>
          
          
        </Switch>
        </div>
   
    </Router>
  );
}

export default App;
