import React from 'react';
import Header from '../components/Header';
const Test = () =>{

const handclick = async (e) =>{
    //
    try {
        const response = await fetch('https://api.smahot.co.il/meeting/test.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
      'Accept': 'application/json'
          },
         
        });

        const responseData = await response.json();
        console.log(responseData);
      } catch (err) {
        console.log(err);
      }
    //
}

return(
    <div>
         <Header />
    
    <div className="container mt-4 mb-4">
    <div>Test</div>
    <button onClick={handclick}>click</button>
    </div>
    </div>
)
}

export default Test;