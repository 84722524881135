import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
const Lids = () =>{

  const [data, setData] = useState([]);
  const [valid, setValid] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        'https://api.smahot.co.il/lids/getLids.php',
      );
      console.log(result.data);
        setData(result.data);
        setValid(true);
      
    };
 
    fetchData();
  }, []);
  


    
   
 

return(
  <div> <Header />
  <div className="container mt-4 mb-4">
    <div>
      

      <table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col">שם מלא</th>
      <th scope="col">טלפון</th>
      <th scope="col">אימייל</th>
      <th scope="col">סוג אירוע</th>
      <th scope="col">כמות מוזמנים</th>
      <th scope="col">סטטוס </th>
    </tr>
  </thead>
  <tbody>

   
  </tbody>
</table>


    </div>
    </div>
 
    </div>
)
}

export default Lids;