import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Logo from '../images/logo.jpg';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useParams
} from "react-router-dom";



const Completeb = (props) => {

  const id = useParams().lid;
  const [mana, setMana] = useState('');
  const [firstname, setFirstname] = useState('');
  const [bname, setBname] = useState('');
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState('טוען...');
  const [value, setValue] = useState(null);
  const [sum, setSum] = useState('0');
  const [errorview, setErrorview] = useState(false);
  /****  function to fetc * */
  /*
  const sendHttpRequest = (method, url, data) => {
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, url);
  
      xhr.responseType = 'json';
  
      if (data) {
        xhr.setRequestHeader('Content-Type', 'application/json');
      }
  
      xhr.onload = () => {
        if (xhr.status >= 400) {
          reject(xhr.response);
        } else {
          resolve(xhr.response);
        }
      };
  
      xhr.onerror = () => {
        reject('Something went wrong!');
      };
  
      xhr.send(JSON.stringify(data));
    });
    return promise;
  };
  */
  /************** */
 
 
 
  useEffect(() => {
   
    const fetchData = async () => {
      const result = await axios(
      `https://api.smahot.co.il/complete/getcompleteb.php?lid=${id}`,
      );
       console.log(result.data);
      if (result.data.status == 1){
        setFirstname(result.data.first_name);
        setBname(result.data.bname);
        setValid(true);
        
      }
      if (result.data == 2){
        setMessage('הפרטים כבר נשלחו אלינו');
      }

      if (result.data == 'no found'){
        setMessage('לא נמצא ליד');
      }

      if(result.data.event_type == 'חתונה'){
        setMana('למנה');
      }

       
      
    };
 
    fetchData();
  }, []);






  const handleSubmit = async e =>{
    e.preventDefault();
    
    if (value == '' || value == null){
      setErrorview(true);
    }
      else{
        
        value.setMinutes(value.getMinutes()+200);
        const mydate = JSON.stringify(value);
        const y = mydate.slice(3, 5);
        const m = mydate.slice(6, 8);
        const d = mydate.slice(9, 11);

       
        
        const datetodb = mydate.slice(1, 5)+'/'+m+'/'+d;
       console.log(JSON.stringify({
          datetodb: datetodb,
          id: id,
          sum: sum
        })); 
        
        try {
          const response = await fetch('https://api.smahot.co.il/complete/completeb.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
        'Accept': 'application/json'
            },
            body: JSON.stringify({
              datetodb: datetodb,
              id: id,
              sum: sum
            })
          });
  
          const responseData = await response.json();
          console.log(responseData);
          if(responseData){
          setValid(false);
          setMessage('תודה לכם, הפרטים נשלחו בהצלחה');
        }
        } catch (err) {
          console.log(err);
        }
        
        
//

      }
      
    }
  
      
  return (
    <div> 
      <div className="container mt-4">
      <img className="image-logo" src={Logo} alt="smahot logo" />
        <div className="col-sm-12">
  {valid ? <div><h3>שלום {bname}, אנו שמחים שסגרתם את העסקה עם   {firstname}</h3>
  <p>ספרו לנו באיזה תאריך האירוע ובכמה סגרתם</p>
            {errorview ? <div class="alert alert-danger" role="alert">
לא מילאת את התאריך לפגישה
</div> : <span>בחרו את תאריך האירוע </span>}
            <div>
              <form onSubmit={handleSubmit}>
                <div>
                <DatePicker
        selected={value}
            onChange={date => setValue(date)}
            dateFormat='dd/MM/yyyy' 
            minDate={new Date()}
            filterDate={date => date.getDay() !== 6 }
            isClearable
            withPortal
      />
                </div>
                <div>
       
                </div>
               <div>
               <span>הכנס את הסכום {mana} </span>
               <div className="col-sm-2 p-0">
               <input type="number" value={sum} onChange={e=>setSum(e.target.value)}/>
               </div>
               </div>
                <div className="mt-4">
                  <button  type="submit" className="btn btn-primary">שלח</button>
                </div>
              </form>
          </div> </div> : <div>{message}</div>}
            
        </div>

      </div>
 
    </div>


  )
}

export default Completeb;