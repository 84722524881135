import React from 'react';
import Header from '../components/Header';
const Category = () =>{
return(
    <div>
         <Header />
    
    <div className="container mt-4 mb-4">
    <div>Category</div>
    </div>
    </div>
)
}

export default Category;