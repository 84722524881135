import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Logo from '../images/logo.jpg';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useParams
} from "react-router-dom";



const CancelBiz = (props) => {

  const id = useParams().lid;
  const [valbtn, setValbtn] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [bname, setBname] = useState('');
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState('טוען...');
  const [value, setValue] = useState(null);
  const [time, setTime] = useState('08:00');
  const [errorview, setErrorview] = useState(false);
  
 
 
 
  useEffect(() => {
   
    const fetchData = async () => {
      const result = await axios(
      `https://api.smahot.co.il/meeting/getmeeting.php?lid=${id}`,
      );
       console.log(result.data);
      if (result.data.status == 1){
        setFirstname(result.data.first_name);
        setBname(result.data.bname);
        setValid(true);
        
      }
      if (result.data == 2){
        setMessage('נקבעה פגישה');
      }

      if (result.data == 'no found'){
        setMessage('לא נמצא ליד');
      }

       
      
    };
 
    fetchData();
  }, []);






  const handleSubmit = async e =>{
    e.preventDefault();
    
    if (value == '' || value == null){
      setErrorview(true);
    }
      else{
        
        value.setMinutes(value.getMinutes()+200);
        const mydate = JSON.stringify(value);
        const y = mydate.slice(3, 5);
        const m = mydate.slice(6, 8);
        const d = mydate.slice(9, 11);

        const datetosms = d+ '/'+m+'/'+y+' 08:00';
        let atime = time.slice(0, 2);
        let btime = time.slice(2, 5);
        atime = parseInt(atime)+3;
        if (atime<10){
          atime = '0' + atime;
        }
        let timetosmsafter = atime+btime;
        const timeafter = d+ '/'+m+'/'+y+' '+timetosmsafter;
        const datetodb = mydate.slice(1, 5)+'/'+m+'/'+d;
       console.log(JSON.stringify({
          datetosms: datetosms,
          datetodb: datetodb,
          id: id,
          time: time,
          timeafter: timeafter
        })); 
      
        
        try {
          const response = await fetch('https://api.smahot.co.il/meeting/create-meeting.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
        'Accept': 'application/json'
            },
            body: JSON.stringify({
              datetosms: datetosms,
              datetodb: datetodb,
              id: id,
              time: time,
              timeafter: timeafter
            })
          });
  
          const responseData = await response.json();
          console.log(responseData);
          if(responseData){
          setValid(false);
          setMessage('התאריך נשלח בהצלחה');
        }
        } catch (err) {
          console.log(err);
        }
        
        
//

      }
      
    }
  
      
  return (
    <div> 
      <div className="container mt-4">

        <div className="col-sm-12">
  {valid ? <div><h3>דיווח על עסקה</h3>
    <p>שלום {bname}, האם אתם רוצה לדווח שלא סגרתם את העסקה עם   {firstname}?</p>
  
  {valbtn ? <div> 
    <h5>האם אתם בטוחים שאתם רוצים לדווח שלא סגרתם את העסקה עם הלקוח?</h5>
    <button   type="button" className="btn btn-danger m-2">כן</button>
    <button  onClick={e => setValbtn(false)} type="button"  className="btn btn-success m-2">לא</button>
    
    
     </div> : 
     <div>

       <button onClick={e =>setValbtn(true)} type="button" className="btn btn-primary">לא סגרנו את העסקה</button> 
       </div>}
  
            <div>
            
          </div> </div> : <div>{message}</div>}
            
        </div>

      </div>
 
    </div>


  )
}

export default CancelBiz;