import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink
  } from "react-router-dom";

function Header() {
  return (
    <div className="Header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <Link className="navbar-brand" to="#">שמחות</Link>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">
    <li className="nav-item">
        <NavLink className="nav-link" to="/test">test</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/">לידים</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/business">עסקים</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/category">קטגוריה</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/addmeeting">פגישות</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/addmeeting-customer">פגישה לקוח</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/completeb">סגירה עסק </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/completec">סגירה לקוח </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/cancel-biz">ביטול עסקה  </NavLink>
      </li>


     
    </ul>
 
  </div>
</nav>
    </div>
  );
}

export default Header;
