import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Logo from '../images/logo.jpg';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  useParams
} from "react-router-dom";



const AddMeeting = (props) => {

  const id = useParams().lid;
  const [firstname, setFirstname] = useState('');
  const [bname, setBname] = useState('');
  const [valid, setValid] = useState(false);
  const [message, setMessage] = useState('טוען...');
  const [value, setValue] = useState(null);
  const [time, setTime] = useState('08:00');
  const [errorview, setErrorview] = useState(false);
  /****  function to fetc * */
  /*
  const sendHttpRequest = (method, url, data) => {
    const promise = new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(method, url);
  
      xhr.responseType = 'json';
  
      if (data) {
        xhr.setRequestHeader('Content-Type', 'application/json');
      }
  
      xhr.onload = () => {
        if (xhr.status >= 400) {
          reject(xhr.response);
        } else {
          resolve(xhr.response);
        }
      };
  
      xhr.onerror = () => {
        reject('Something went wrong!');
      };
  
      xhr.send(JSON.stringify(data));
    });
    return promise;
  };
  */
  /************** */
 
 
 
  useEffect(() => {
   
    const fetchData = async () => {
      const result = await axios(
      `https://api.smahot.co.il/meeting/getmeeting.php?lid=${id}`,
      );
       console.log(result.data);
      if (result.data.status == 1){
        setFirstname(result.data.first_name);
        setBname(result.data.bname);
        setValid(true);
        
      }
      if (result.data == 2){
        setMessage('נקבעה פגישה');
      }

      if (result.data == 'no found'){
        setMessage('לא נמצא ליד');
      }

       
      
    };
 
    fetchData();
  }, []);






  const handleSubmit = async e =>{
    e.preventDefault();
    
    if (value == '' || value == null){
      setErrorview(true);
    }
      else{
        
        value.setMinutes(value.getMinutes()+200);
        const mydate = JSON.stringify(value);
        const y = mydate.slice(3, 5);
        const m = mydate.slice(6, 8);
        const d = mydate.slice(9, 11);

        const datetosms = d+ '/'+m+'/'+y+' 08:00';
        let atime = time.slice(0, 2);
        let btime = time.slice(2, 5);
        atime = parseInt(atime)+3;
        if (atime<10){
          atime = '0' + atime;
        }
        let timetosmsafter = atime+btime;
        const timeafter = d+ '/'+m+'/'+y+' '+timetosmsafter;
        const datetodb = mydate.slice(1, 5)+'/'+m+'/'+d;
       console.log(JSON.stringify({
          datetosms: datetosms,
          datetodb: datetodb,
          id: id,
          time: time,
          timeafter: timeafter
        })); 
        // console.log(objdate);
        // fetch

        /*
        axios.post('https://api.smahot.co.il/meeting/create-meeting.php', {
          datetosms: datetosms,
              datetodb: datetodb,
              id: id,
              time: time
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
        */
        
       /*
       axios.post('https://reqres.in/api/register', {
        email: "eve.holt@reqres.in",
        password: "pistol"
        })
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
        
      */
        /***** true function  * */
        
        try {
          const response = await fetch('https://api.smahot.co.il/meeting/create-meeting.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
        'Accept': 'application/json'
            },
            body: JSON.stringify({
              datetosms: datetosms,
              datetodb: datetodb,
              id: id,
              time: time,
              timeafter: timeafter
            })
          });
  
          const responseData = await response.json();
          console.log(responseData);
          if(responseData){
          setValid(false);
          setMessage('התאריך נשלח בהצלחה');
        }else{
          setMessage('שגיאה');
        }
        } catch (err) {
          console.log(err);
        }
        
        
//

      }
      
    }
  
      
  return (
    <div> 
      <div className="container mt-4">
        <img className="image-logo" src={Logo} alt="smahot logo" />
        <div className="col-sm-12">
  {valid ? <div><h3>שלום {bname}, באיזה תאריך קבעת פגישה עם  {firstname}?</h3>
            {errorview ? <div class="alert alert-danger" role="alert">
לא מילאת את התאריך לפגישה
</div> : <span>בחרו תאריך לפגישה</span>}
            <div>
              <form onSubmit={handleSubmit}>
                <div>
                <DatePicker
        selected={value}
            onChange={date => setValue(date)}
            dateFormat='dd/MM/yyyy' 
            minDate={new Date()}
            filterDate={date => date.getDay() !== 6 }
            isClearable
            withPortal
      />
                </div>
                <div>
       
                </div>
               <div>
               <span>בחרו את השעה </span>
               <div className="col-sm-2 p-0">
               <select className="form-control p-0" onChange={e=>setTime(e.target.value)}>
      <option value="08:00" >08:00</option>
      <option value="08:30">08:30</option>
      <option value="09:00">09:00</option>
      <option value="09:30">09:30</option>
      <option value="10:00">10:00</option>
      <option value="10:30">10:30</option>
      <option value="11:00">11:00</option>
      <option value="11:30">11:30</option>
      <option value="12:00">12:00</option>
      <option value="12:30">12:30</option>
      <option value="13:00">13:00</option>
      <option value="13:30">13:30</option>
      <option value="14:00">14:00</option>
      <option value="14:30">14:30</option>
      <option value="15:00">15:00</option>
      <option value="15:30">15:30</option>
      <option value="16:00">16:00</option>
      <option value="16:30">16:30</option>
      <option value="17:00">17:00</option>
      <option value="17:30">17:30</option>
      <option value="18:00">18:00</option>
      <option value="18:30">18:30</option>
      <option value="19:00">19:00</option>
      <option value="19:30">19:30</option>
      <option value="20:00">20:00</option>
    </select>
               </div>
               </div>
                <div className="mt-4">
                  <button  type="submit" className="btn btn-primary">שלח</button>
                </div>
              </form>
          </div> </div> : <div>{message}</div>}
            
        </div>

      </div>
 
    </div>


  )
}

export default AddMeeting;