import React from 'react';
import Header from '../components/Header';
const Business = () =>{
return(
    <div>
        <Header />
   
    <div className="container mt-4 mb-4">
    <div>Business</div>
    </div>
    </div>
)
}

export default Business;